// FAQAccordion.tsx
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"; // Ensure you have @mui/material installed
import React, { useState } from "react";
import { FaArrowDown, FaPlus } from "react-icons/fa6";
interface FAQItem {
  id: string;
  question: string;
  answer: string;
}

interface FAQAccordionProps {
  faqs: FAQItem[];
}

const FAQAccordion: React.FC<FAQAccordionProps> = ({ faqs }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <section className="py-5">
      <div className="container md:mt-5">
        <div className="text-center">
          <h5 className="mb-5 inline-flex rounded-full border px-3.5 py-1 text-sm font-medium">
            FAQ <span className="text-gray-400">&nbsp;•&nbsp;</span> Help Center
          </h5>
          <h2 className="mb-5 md:text-4xl text-3xl font-semibold">Have a question?</h2>
          <p className=" mx-auto lg:w-1/2 text-gray-500 lg:text-base md:text-base sm:text-base text-sm">
            Your Questions, Answered. Everything You Need to Know About Our
            Tools and Features
          </p>
        </div>
        <div className="mt-8">
          {faqs.map((faq, idx) => (
            <Accordion
              key={faq.id}
              className="!shadow-none border-0 !rounded-3xl p-3 before:hidden mt-3"
              expanded={expanded === faq.id}
              onChange={handleChange(faq.id)}
            >
              <AccordionSummary
                expandIcon={
                  <span className="w-10 h-10 rounded-full border flex justify-center items-center">
                    <FaPlus />
                  </span>
                }
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <h5 className="mb-0 text-dark-blue lg:text-xl md:text-xl text-lg font-semibold">
                  {faq.question}
                </h5>
              </AccordionSummary>
              <AccordionDetails>
                <p className="lg:text-lg md:text-lg text-sm text-dark-blue text-start">
                  {faq.answer}
                </p>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordion;
