"use client";
import React, { useState, ReactNode } from "react";

interface TiltCardProps {
  children: ReactNode; // Content inside the card

  transitionSpeed?: string; // Speed of the transition (e.g., "0.5s")
}

const TiltCard: React.FC<TiltCardProps> = ({
  children,
  transitionSpeed = "0.5s",
}) => {
  const [transformStyle, setTransformStyle] = useState({
    transform:
      "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
  });

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const card = e.currentTarget; // Reference to the card
    const { left, top, width, height } = card.getBoundingClientRect();

    // Calculate the mouse position relative to the card
    const x = e.clientX - left;
    const y = e.clientY - top;

    // Normalize values between -1 and 1
    const rotateX = -((y / height) * 2 - 1) * 10; // Adjust rotation strength
    const rotateY = ((x / width) * 2 - 1) * 10; // Adjust rotation strength

    // Update the transform style
    setTransformStyle({
      transform: `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(1, 1, 1)`,
    });
  };

  const handleMouseLeave = () => {
    // Reset the transformation when the mouse leaves the card
    setTransformStyle({
      transform:
        "perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1)",
    });
  };

  return (
    <div
      className="h-full"
      style={{
        ...transformStyle,
        transition: `transform ${transitionSpeed} ease-out`, // Smooth transition
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </div>
  );
};

export default TiltCard;
