import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import { CustomButton } from "../../../../components";
interface FormProps {
  btnClass?: string;
}
const HeroFormSection: React.FC<FormProps> = ({ btnClass }) => {
  const [phone, setPhone] = useState<string | undefined>(undefined);
 
  return (
    <>
      <div className="lg:text-base md:text-lg text-sm">
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-2 gap-y-3 ">
          <div>
            <label htmlFor="first-name">First Name</label>
            <input
              type="text"
              id="first-name"
              className="focus:outline-none h-11 w-full rounded-full border-2 border-gray-300 bg-transparent mt-2  p-2.5  "
            />
          </div>
          <div>
            <label htmlFor="last-name">Last Name</label>
            <input
              type="text"
              id="last-name"
              className="focus:outline-none h-11 w-full rounded-full border-2 border-gray-300 bg-transparent mt-2  p-2.5  "
            />
          </div>
          <div>
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              className="focus:outline-none w-full h-11 rounded-full border-2 border-gray-300 bg-transparent mt-2  p-2.5  "
            />
          </div>
          <div>
            <label>Phone</label>
            <div className=" border-2 border-gray-300 h-11 mt-2  rounded-full px-2.5 get-hired-modal-phone-number-input ">
              <PhoneInput
                placeholder="Phone number"
                value={phone}
                onChange={(value: string | undefined) => setPhone(value)}
                defaultCountry="AU"
                // countrySelectProps={{ unicodeFlags: true }}
                international
                // focusInputOnCountrySelection={false}
                numberInputProps={{ maxLength: 15 }}
                countrySelectProps={{
                  className: "w-5  text-black focus:outline-none bg-[#F7F7FC]",
                }}
                name="phoneNumber"
                containerComponentProps={{
                  className: "w-full h-11 !flex items-center",
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <label htmlFor="company">Email</label>
          <input
            type="email"
            id="emil"
            className="focus:outline-none w-full rounded-full border-2 border-gray-300 bg-transparent mt-2  p-2.5  "
          />
        </div>

        <div className="flex flex-col items-center space-y-3 pt-6">
          <span className="text-xs text-[#334F74]">
            By submitting your details you agree to our{" "}
            <Link className="text-[#334F74]" to="/term-condition">
              terms & conditions
            </Link>{" "}
            &{" "}
            <Link className="text-[#334F74]" to="/privacy-policy">
              privacy policy
            </Link>
            .
          </span>
          <CustomButton
            btntext="Send Details"
            rounded=" rounded-2xl"
            addclass={`shrink-0 font-medium ${btnClass}`}
          />
        </div>
      </div>
    </>
  );
};

export default HeroFormSection;
