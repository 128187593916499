import React, { useEffect, useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { PiDeviceMobileSpeakerLight } from "react-icons/pi";

import { Link } from "react-router-dom";
import { CustomButton, CustomCard, Navbar } from "../../components";
import Footer from "../../components/Footer";
import { tabeArrowSvg } from "../../components/TableArrowSvg";
import useCountdown from "../../hooks/useCountdown";
import HeroFormSection from "./_components/sections/HeroFormSection";
const TimeLimitedOffer = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);

  const signup = "https://console.timelink.au/auth/signup";
  const limitedTime = process.env.REACT_APP_LIMITED_TIME_OFFER_TIME;
  const timeLeft = useCountdown(`${limitedTime}`) ?? {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  const entries = Object.entries(timeLeft);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="lg:fixed lg:top-0 lg:start-0 w-full  z-50 bg-white">
        <Navbar />
        {isScrolled && (
          <div className=" w-full transition delay-75 duration-700 ease-in-out transform  flex justify-between h-16 px-3 bg-white  shadow-sm inset-3 items-center   ">
            <div className="lg:flex md:flex hidden gap-1 items-center ">
              {timeLeft &&
                entries.map(([unit, value], index) => (
                  <div
                    className="flex items-center text-center text-black"
                    key={unit}
                  >
                    <div className=" p-1  space-y-0 rounded-lg ">
                      <div className="text-base font-bold ">{value}</div>
                      <div className="text-[8px] uppercase">{unit}</div>
                    </div>
                    {index !== entries.length - 1 && (
                      <span className="text- font-normal ms-1">:</span>
                    )}
                  </div>
                ))}
            </div>
            <div className="flex items-center  justify-end w-full gap-2">
              <p className="text-xs  mb-0">
                WAS <span className="line-through">$89</span>, NOW FROM{" "}
                <strong className="text-darkblue text-base">$45</strong>
              </p>
              <Link to="/" className="no-underline">
                <CustomButton
                  btntext={"Get Offer"}
                  rounded="rounded-lg"
                  padding="p-2"
                  addclass="text-sm font-md"
                />
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className="lg:h-3 "></div>
      <main className=" lg:pt-[149px]">
        <br />
        <div>
          <div className="w-10/12 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-4 ">
            <div className="flex items-center">
              <div>
                <h1 className="lg:text-[40px]  md:text-[35px] sm:text-[32px] text-[28px]   font-semibold poppins">
                  The complete TimeLink system
                </h1>

                <p className="lg:text-lg md:text-base sm:text-base text-sm lg:mt-5 md:mt-5 mt-3">
                  Simple, efficient and powerful. Upgrade your take-away
                  business with TImeLink’s all-in-one digital order tracking
                  system. Enhance customer experience and streamline your
                  operations effortlessly.
                </p>
                <p className="text-xs font-bold">
                  TimeLink Connect ($74.99 value) &nbsp;&nbsp;|&nbsp;&nbsp; 1
                  Android Tablet &nbsp;&nbsp;|&nbsp;&nbsp; SMS Notifications |
                  Real-time order tracking
                </p>
                <p className="text-base font-bold">
                  Save up to 50% for the next 3 months + hardware included!*
                </p>
                <p className="text-sm font-bold">
                  Prices starting from as low as $30 / Month**
                </p>
              </div>
            </div>
            <div className="space-y-2 text-sm">
              <HeroFormSection />
            </div>
          </div>
        </div>
      </main>
      {/* -timer------------ */}
      <div
        className="w-10/12 mx-auto text-center bg-cover	bg-no-repeat bg-center p-3 mt-12 p border rounded-3xl"
        style={{ backgroundImage: `url("/images/bg-marque.svg")` }}
      >
        <h1 className="lg:text-4xl md:text-3xl sm:text-2xl text-xl  text-darkblue font-bold mt-3">
          Limited Time Offer!
        </h1>
        <div className="flex flex-wrap justify-center space-x-4 text-center items-center">
          {timeLeft &&
            entries.map(([unit, value], index) => (
              <React.Fragment key={unit}>
                <div className="lg:p-4 md:p-4 p-2 space-y-3 rounded-lg">
                  <div className="lg:text-sm md:text-sm text-xs text-gray-300 uppercase">
                    {unit}
                  </div>
                  <div className="lg:text-7xl md:text-5xl sm:text-4xl text-3xl font-normal text-[#4D4C59]">
                    {value}
                  </div>
                </div>
                {index !== entries.length - 1 && (
                  <span className="lg:text-7xl text-5xl lg:block md:block hidden font-normal">
                    :
                  </span>
                )}
              </React.Fragment>
            ))}
        </div>
      </div>
      {/* pricing  */}
      <div className="w-9/12 mx-auto mt-5">
        <div className=" lg:my-28 md:my-20 sm:my-16 my-10 grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4  ">
          <div>
            <div
              className={`w-16 h-16 flex justify-center items-center bg-[#F4F6FF] rounded-full `}
            >
              <PiDeviceMobileSpeakerLight size={30} className="text-darkblue" />
            </div>
            <p className="font-bold mb-0 mt-5">Time Link</p>
            <h1 className="font-bold text-3xl mt-2">Essential Plan</h1>
            <p className="text-sm my-3">1,250* texts for one low price </p>
            <div className="flex items-center gap-2">
              <span className="font-semibold">Now</span>
              <h1 className="text-darkblue font-bold  text-4xl">
                $45 <sub className="text-sm uppercase">/Month</sub>
              </h1>
            </div>
            <div className="font-semibold flex gap-2">
              <span className="">WAS</span>
              <div className="font-bold">
                <span className="line-through">$89</span>
                <span className="">/Month</span>
              </div>
            </div>
            <Link to="/" className="no-underline">
              <CustomButton
                rounded={"rounded-full"}
                btntext={"plans and pricing"}
                addclass="w-1/2 mt-4"
              />
            </Link>
          </div>
          <div>
            <h5 className="font-bold">What's included:</h5>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Digital Notification system</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Comprehensive Analytics & Insights</p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">1250 SMS Included</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">Square POS Integration</p>
            </div>

            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                TimeLink Connect Device Included ($74.99 Value)
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                1 FREE Accumulating Marketing Credit EACH Month ($75 Value){" "}
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                Flexible cancellation - simply return the hardware.{" "}
              </p>
            </div>
            <div className="flex gap-3 items-center my-3">
              <BsCheck2Circle className="shrink-0 text-darkblue" size="20" />
              <p className="mb-0 text-sm">
                Email Support from Real, Live Humans{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-10/12 mx-auto ">
        <p className="mb-0 text-sm text-center">
          *Monthly messages are limited to 1,250. If monthly limit is exceeded -
          customers will still receives web-based notifications (where
          applicable). Contact sales if you need more messages or if you have
          any questions.
        </p>
      </div>
      <br className="lg:block md:block hidden" />
      <br className="lg:block md:block hidden" />
      <br />
      <div className="w-10/12 mx-auto py-4 bg-[#DEEDF9] rounded-3xl lg:mt-5 mt-4  ">
        <div className="lg:flex justify-end mt-2 items-center gap-4">
          <div className="lg:w-[75%] w-full p-3 ">
            <div>
              <h1 className="lg:text-[50px] md:text-4xl  text-3xl font-semibold mt-lg-5">
                Queue Management That Doesn't Suck
              </h1>
              <br className="lg:block md:block hidden"/>
              <p className=" lg:mt-5 md:mt-5 mt-3 text-sm">
                TimeLink modernises queue management with an efficient digital
                solution. Using SMS and QR codes, it keeps customers updated on
                their order status in real-time. This reduces the need for
                physical buzzers and streamlines the customer experience,
                enhancing service efficiency subtly and effectively.
              </p>

              <div className="mt-4 flex ">
                <Link to={signup} className="no-underline lg:text-lg md:text-lg sm:text-sm">
                  <CustomButton
                    rounded={"rounded-full"}
                    btntext={"Get Started for free"}
                    addclass="font-medium"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className="lg:w-[15%] w-full">
            <div className="flex justify-end">
              <img
                src="/images/queueManagment-image.png"
                className="lg:w-full w-[30%]"
                alt="image not found"
              />
            </div>

            {/* <img
              src={computerMd}
              className="w-100 d-lg-none d-block"
              alt="image not found"
            /> */}
          </div>
        </div>
        <div className="flex justify-end px-4 mb-5">
          <p className="m-0 text-sm flex gap-2 items-center">
            <BsCheck2Circle className="text-darkblue shrink-0" />
            No app download required for your customers
          </p>
        </div>
      </div>
      {/* -------------cards----------- */}
      <br className="lg:block md:block hidden" />  <br className="lg:block md:block hidden"/>
      <br />
      <div className="w-10/12 mx-auto grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 grid-cols-1 gap-4 my-12 poppins">
        <CustomCard
          heading={"Streamline Queues Efficiently"}
          description={
            "Reduce wait times with automated queue management for faster service."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="41"
              height="29"
              viewBox="0 0 41 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.4167 0.166748H2.58332C2.10814 0.166748 1.65243 0.355513 1.31642 0.691515C0.980421 1.02752 0.791656 1.48324 0.791656 1.95841V12.7084C0.791656 13.1836 0.980421 13.6393 1.31642 13.9753C1.65243 14.3113 2.10814 14.5001 2.58332 14.5001H38.4167C38.8918 14.5001 39.3476 14.3113 39.6836 13.9753C40.0196 13.6393 40.2083 13.1836 40.2083 12.7084V1.95841C40.2083 1.48324 40.0196 1.02752 39.6836 0.691515C39.3476 0.355513 38.8918 0.166748 38.4167 0.166748ZM36.625 10.9167H4.37499V3.75008H36.625V10.9167ZM40.2083 19.8751C40.2083 20.3503 40.0196 20.806 39.6836 21.142C39.3476 21.478 38.8918 21.6667 38.4167 21.6667H2.58332C2.10814 21.6667 1.65243 21.478 1.31642 21.142C0.980421 20.806 0.791656 20.3503 0.791656 19.8751C0.791656 19.3999 0.980421 18.9442 1.31642 18.6082C1.65243 18.2722 2.10814 18.0834 2.58332 18.0834H38.4167C38.8918 18.0834 39.3476 18.2722 39.6836 18.6082C40.0196 18.9442 40.2083 19.3999 40.2083 19.8751ZM40.2083 27.0417C40.2083 27.5169 40.0196 27.9726 39.6836 28.3086C39.3476 28.6447 38.8918 28.8334 38.4167 28.8334H2.58332C2.10814 28.8334 1.65243 28.6447 1.31642 28.3086C0.980421 27.9726 0.791656 27.5169 0.791656 27.0417C0.791656 26.5666 0.980421 26.1108 1.31642 25.7748C1.65243 25.4388 2.10814 25.2501 2.58332 25.2501H38.4167C38.8918 25.2501 39.3476 25.4388 39.6836 25.7748C40.0196 26.1108 40.2083 26.5666 40.2083 27.0417Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Real-Time Updates"}
          description={
            "Keep customers informed with  SMS and web notifications when their order becomes ready. "
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.5 29.75V12.25H14V29.75H10.5Z" fill="#1B4FF8" />
              <path d="M28 12.25V29.75H31.5V12.25H28Z" fill="#1B4FF8" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.5 5.25H38.5V36.75H3.5V5.25ZM7 8.75V33.25H35V8.75H7Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Flexible Integrations"}
          description={
            "Seamlessly integrate with your existing systems Square POS for smooth operations."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="41"
              height="29"
              viewBox="0 0 41 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M38.4167 0.166748H2.58332C2.10814 0.166748 1.65243 0.355513 1.31642 0.691515C0.980421 1.02752 0.791656 1.48324 0.791656 1.95841V12.7084C0.791656 13.1836 0.980421 13.6393 1.31642 13.9753C1.65243 14.3113 2.10814 14.5001 2.58332 14.5001H38.4167C38.8918 14.5001 39.3476 14.3113 39.6836 13.9753C40.0196 13.6393 40.2083 13.1836 40.2083 12.7084V1.95841C40.2083 1.48324 40.0196 1.02752 39.6836 0.691515C39.3476 0.355513 38.8918 0.166748 38.4167 0.166748ZM36.625 10.9167H4.37499V3.75008H36.625V10.9167ZM40.2083 19.8751C40.2083 20.3503 40.0196 20.806 39.6836 21.142C39.3476 21.478 38.8918 21.6667 38.4167 21.6667H2.58332C2.10814 21.6667 1.65243 21.478 1.31642 21.142C0.980421 20.806 0.791656 20.3503 0.791656 19.8751C0.791656 19.3999 0.980421 18.9442 1.31642 18.6082C1.65243 18.2722 2.10814 18.0834 2.58332 18.0834H38.4167C38.8918 18.0834 39.3476 18.2722 39.6836 18.6082C40.0196 18.9442 40.2083 19.3999 40.2083 19.8751ZM40.2083 27.0417C40.2083 27.5169 40.0196 27.9726 39.6836 28.3086C39.3476 28.6447 38.8918 28.8334 38.4167 28.8334H2.58332C2.10814 28.8334 1.65243 28.6447 1.31642 28.3086C0.980421 27.9726 0.791656 27.5169 0.791656 27.0417C0.791656 26.5666 0.980421 26.1108 1.31642 25.7748C1.65243 25.4388 2.10814 25.2501 2.58332 25.2501H38.4167C38.8918 25.2501 39.3476 25.4388 39.6836 25.7748C40.0196 26.1108 40.2083 26.5666 40.2083 27.0417Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Analytics & Reporting"}
          description={
            "Gain insights with detailed reports on customer flow and wait times."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="43"
              height="44"
              viewBox="0 0 43 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 33.8013L13.7261 20.3492C13.9303 19.9406 14.2828 19.6256 14.7116 19.4683C16.1618 18.9364 17.0333 19.1096 17.3262 19.9881L19.7549 27.2744C19.8994 27.7076 20.2038 28.0694 20.606 28.2857C21.9664 29.0173 22.8536 28.969 23.2677 28.1408L32.0833 10.5096M23.125 11.3411L30.7887 9.28763C31.0706 9.2121 31.3665 9.20655 31.651 9.27144C32.6537 9.50021 33.2296 9.89258 33.3786 10.4486L35.538 18.5078"
                stroke="#1B4FF8"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Create Custom Surveys"}
          description={
            "gather feedback, enhance customer engagement, and boost your Google reviews."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="27"
              height="27"
              viewBox="0 0 27 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.056 6.1041C25.97 5.8031 25.5826 5.7171 25.325 5.9321L20.982 10.2751C20.6376 10.6191 20.122 10.6191 19.7776 10.2751L16.7246 7.2221C16.3806 6.8781 16.3806 6.3621 16.7246 6.0181L21.1106 1.6321C21.3256 1.4171 21.2396 1.0301 20.9386 0.901098C20.208 0.729098 19.434 0.600098 18.66 0.600098C14.102 0.600098 10.447 4.5561 10.963 9.2001C11.049 9.9311 11.221 10.5761 11.479 11.2211L1.46 21.2831C0.299001 22.4441 0.299001 24.3791 1.46 25.5401C2.062 26.1421 2.836 26.4431 3.61 26.4431C4.384 26.4431 5.158 26.1425 5.76 25.5401L15.779 15.5211C16.424 15.7791 17.1124 15.9511 17.8 16.0371C22.487 16.5531 26.4 12.8981 26.4 8.3401C26.4 7.5661 26.271 6.7921 26.056 6.1041Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
        <CustomCard
          heading={"Marketing Campaigns"}
          description={
            "Launch targeted marketing campaigns to connect with customers and drive repeat business."
          }
          addClass={"py-5"}
          imgClass={"mx-auto"}
          headingClass={"text-center"}
          img={
            <svg
              width="44"
              height="43"
              viewBox="0 0 44 43"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M36.3001 18.2391C35.7317 12.5416 32.2851 3.58325 21.6334 3.58325C10.9817 3.58325 7.53508 12.5416 6.96674 18.2391C4.97301 18.9786 3.65792 20.8492 3.6667 22.9333V25.4416C3.6667 28.2122 5.96502 30.4583 8.80008 30.4583C11.6351 30.4583 13.9334 28.2122 13.9334 25.4416V22.9333C13.9242 20.8942 12.6574 19.062 10.7251 18.2928C11.0917 14.9962 12.8884 7.16659 21.6334 7.16659C30.3784 7.16659 32.1567 14.9962 32.5234 18.2928C30.595 19.0637 29.3346 20.8971 29.3334 22.9333V25.4416C29.3414 27.3676 30.473 29.1204 32.2484 29.9566C31.4784 31.372 29.5167 33.2891 24.6951 33.8624C23.7312 32.4321 21.8665 31.8683 20.2446 32.5167C18.6226 33.1651 17.6968 34.8445 18.0351 36.5249C18.3733 38.2053 19.881 39.4166 21.6334 39.4166C23.0182 39.4091 24.2805 38.6398 24.8967 37.4278C32.7617 36.5499 35.2734 32.5903 36.0617 30.2612C38.1945 29.586 39.6288 27.6323 39.6001 25.4416V22.9333C39.6089 20.8492 38.2938 18.9786 36.3001 18.2391ZM10.2667 25.4416C10.2667 26.2332 9.6101 26.8749 8.80008 26.8749C7.99006 26.8749 7.33341 26.2332 7.33341 25.4416V22.9333C7.33341 22.4212 7.61296 21.948 8.06674 21.6919C8.52053 21.4359 9.07962 21.4359 9.53341 21.6919C9.9872 21.948 10.2667 22.4212 10.2667 22.9333V25.4416ZM33.0001 22.9333C33.0001 22.1416 33.6567 21.4999 34.4667 21.4999C35.2768 21.4999 35.9334 22.1416 35.9334 22.9333V25.4416C35.9334 26.2332 35.2768 26.8749 34.4667 26.8749C33.6567 26.8749 33.0001 26.2332 33.0001 25.4416V22.9333Z"
                fill="#1B4FF8"
              />
            </svg>
          }
        />
      </div>
      {/* No True Comparison.  */}
      <br className="lg:block md:block hidden"/>
      <br className="lg:block md:block hidden"/>
      <div className="w-10/12 mx-auto overflow-hidden mt-12">
        <div className="flex justify-center ">
          <div className="border text-black font-medium border-gray-200 px-5 py-2 text-xs leading-tight  rounded-full">
            Unleash the Power of TimeLink
          </div>
        </div>
        <div className=" text-center">
          <h1 className="lg:text-5xl md:text-4xl sm:text-4xl text-3xl font-semibold mt-3">No True Comparison.</h1>
          <p className="lg:text-base md:text-base   text-sm text-gray-500 mt-4">
            TimeLink stands alone in the field of queue management. With our
            innovative use of technology to streamline customer interaction and
            enhance operational efficiency, there simply isn’t a comparable
            alternative on the market. We offer a unique blend of real-time
            tracking, digital notifications, and customer engagement tools that
            traditional buzzer systems can’t match.
          </p>
        </div>
        <table className="w-full mt-12 rounded-2xl">
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th align="center">Others</th>
              <th align="center">
                <img src="/images/logo.png" className="w-24" alt="w8" />
              </th>
            </tr>
          </thead>
          <br />
          <tbody className="border rounded-2xl ">
            <tr className=" rounded-tl-xl rounded-tr-xl">
              <td className="p-2" colSpan={2}>
                Real-Time Order Tracking
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)] ">
              <td className="p-2" colSpan={2}>
                SMS & QR Code Notifications
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Data & Analytics
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Customisable Customer Engagement
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Cost Efficiency & Scalability
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Enhanced Hygiene
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>

            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Integrated Marketing Tools
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Device-Free Experience
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                Collect Google Reviews
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className="bg-[rgba(43,43,43,0.02)]  ">
              <td className="p-2" colSpan={2}>
                Create Surveys
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
            <tr className=" ">
              <td className="p-2" colSpan={2}>
                No app download for your customers.
              </td>
              <td align="center" className="p-2">
                <span className="w-8 h-8 flex justify-center items-center bg-gray-200 rounded-full ">
                  -
                </span>
              </td>
              <td className="p-2" align="center">
                {tabeArrowSvg()}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center  my-16">
        <p className="inline-block rounded-full bg-[#F5F7F9] px-5 py-2 text-xs leading-tight text-[#A2B2C9] [&_a]:text-blue-700">
          Need a specific feature ? &nbsp;
          <Link className="text-[#FCA7FF]" to="#">
            Contact us
          </Link>
        </p>
      </div>
      {/* == fotter  */}
      <Footer />
    </>
  );
};

export default TimeLimitedOffer;
