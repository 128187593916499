import { useEffect, useState } from "react";
import { CiMenuFries } from "react-icons/ci";
import { FaAngleLeft } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomButton from "./CustomButton";

const Navbar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState("home");
  const signup = "https://console.timelink.au/auth/signup";
  const login = "https://console.timelink.au/auth/login";

  const sections = [
    { id: "home", label: "Home" },
    { id: "features", label: "Features" },
    { id: "how-it-works", label: "How it Works" },
    { id: "pricing", label: "Pricing" },
    { id: "faq", label: "FAQ" },
    { id: "get-started", label: "Get Started" },
  ];

  // Scroll to section smoothly
  const scrollToSection = async (id: string) => {
    if (id === "get-started") {
      // Redirect to an external URL for 'Get Started'
      window.location.href = signup;
    } else {
      if (pathname != "/") {
        navigate("/");
      }
      // Scroll to the section for other ids
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setActiveSection(id);
      }
    }
  };

  // Highlight active section on scroll
  useEffect(() => {
    const handleScroll = () => {
      sections.forEach(({ id }) => {
        const element = document.getElementById(id);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 50 && rect.bottom >= 50) {
            setActiveSection(id);
          }
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const ActiveClass = () => {
    return " bg-white text-darkCyan font-semibold ";
  };
  const UnActiveClass = () => {
    return " bg-darkCyan text-white font-normal  font-medium ";
  };
  const SidebarLinks = () => {
    return (
      <nav className="w-full lg:flex gap-2 items-center justify-between    px-4 py-2">
        <Link to="/">
          <img src="/images/logo.png" className="w-36" alt="image not found" />
        </Link>
        <ul className=" lg:flex  gap-3 px-3 p-2 md:text-base text-sm lg:bg-[#F6FBFF] text-gray-500 items-center lg:border  rounded-full mb-0">
          {/* <NavLink
            to={"/"}
            className={({ isActive }: { isActive: boolean }) =>
              ` text-black flex gap-2 items-center p-3 text-lg rounded-lg my-2  ${
                isActive ? ActiveClass() : UnActiveClass()
              }`
            }
          >
            <PiCirclesFourFill size={20} />
            Dashboard
          </NavLink> */}
          {/* pathname.startsWith("/users") ? ActiveClass() : UnActiveClass() */}
          {sections.map(({ id, label }) => (
            <li
              key={id}
              className={`p-2 px-3 rounded-full cursor-pointer ease-in duration-300  ${
                activeSection === id ? " bg-gray-200" : " text-black"
              }`}
              onClick={() => scrollToSection(id)}
            >
              {label}
            </li>
          ))}
        </ul>
        <div className="flex flex-wrap gap-2 items-center md:text-base text-sm ">
          <Link to={login} className="no-underline">
            <CustomButton
              btntext="Sign In"
              addclass="font-medium"
              bg="bg-white"
              border="border"
              textColor="text-black"
            />
          </Link>
          <Link to={signup} className="no-underline">
            <CustomButton
              btntext="Join Now"
              addclass="font-medium"
              bg="bg-gray-200"
              textColor="text-black"
            />
          </Link>
        </div>
      </nav>
    );
  };
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);
  return (
    <>
      <div
        className="text-center py-4 text-white lg:flex md:flex hidden w-full md:text-base text-sm"
        style={{
          background: " #1814F3",
        }}
      >
        <p className="mb-0">
          <strong>✨New✨ Features for Small businesses! </strong> Use TimeLink
          to boost your customer engagement and crush your goals!           <Link to={"/contact-sale"} className="no-underline text-white">Learn more!</Link>
        </p>
      </div>
      <div className="w-11/12 mx-auto">
        {/* Sidebar */}
        <div className="hidden lg:block sidebar">{SidebarLinks()}</div>
        <div className="lg:hidden block sidebar bg-white">
          <div className="flex justify-between items-center bg-bg-white p-2 border-b ">
            <div className="p-2 bg-white rounded-2xl">
              <img src="/images/logo.png" width={150} alt="" />
            </div>{" "}
            <button
              onClick={toggleSidebar}
              className=" focus:outline-none  bg-white p-2 rounded-xl"
            >
              <CiMenuFries size={30} />
            </button>
          </div>

          {isSidebarOpen && (
            <div
              className={`fixed top-0 left-0  w-full  z-10 bg-[#00000087] transition-transform transform ${
                isSidebarOpen ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              <div className="w-1/2  relative ">
                <button
                  onClick={toggleSidebar}
                  className=" w-8 h-8 flex justify-center items-center focus:outline-none absolute top-8 -right-4 bg-darkblue  text-white bg- rounded-full"
                >
                  <FaAngleLeft />
                </button>
                <div className="w-full h-screen overflow-y-auto bg-white">
                  {SidebarLinks()}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Navbar;
